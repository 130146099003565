import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import AclAction from "../Components/ACL/AclAction";

import CustomReactTooltip from "../Components/CustomReactTooltip";
import DailogNew from "../Components/DailogNew";
import DynamicTableComponent from "../Components/DynamicTableComponent";

import Search from "../Components/searchComponent";
import { ClientServices } from "../Services/Client";
import { activeMenuTab } from "../Store/Actions/User";
import DetailsDateTimeFormatter from "../Utils/DetailsDateTimeFormatter";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import Slugify from "../Utils/Slugify";
import Toast from "../Utils/Toast";
import { clientRoles, clients } from "../Store/Actions/Client";
import CustomDropdownList from "../Components/CustomDropdownList";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavLink,
} from "reactstrap";
import decodeJWT from "../lib/decodeJWT";
import Copy from "../Utils/Copy";
import { ApiCredentialServices } from "../Services/ApiCredentialServices";
import { UserServices } from "../Services/User";
import CustomPPTUploader from "../Components/CustomPPTUploader";
import ListUserSelectedStatus from "./ListUserSelectedStatus";
import ListClientUploadedUserStatus from "./ListClientUploadUserStatus";
import Notification from "../Components/Notification";

const tableHeader = [
  {
    id: "name",
    title: "Name",
    sortable: true,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "email",
    title: "Email",
    sortable: true,
    style: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
  },
  {
    id: "role",
    title: "Role",
    sortable: true,
    style: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
  },
  {
    id: "logged_by",
    title: "Logged By",
    sortable: true,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: true,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "last_login_at",
    title: "Last Login At",
    sortable: true,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "is_active_apis",
    title: "Active API'S",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "center" },
    visibility: true,
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    headerClass: "text-center",
    style: { textTransform: "capitalize" },
    rowStyle: { textAlign: "center", textTransform: "capitalize" },
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerClass: "text-center",
    style: { textAlign: "center", textTransform: "capitalize" },
    rowStyle: {
      textAlign: "center",
      textTransform: "capitalize",
      whiteSpace: "nowrap",
    },
  },
];
const showActions = false;
class ListClientUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.USER_DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      status: [
        { id: 3, name: "All Status", is_selected: true },
        { id: 1, name: "Active", is_selected: false },
        { id: 0, name: "InActive", is_selected: false },
        { id: 2, name: "Pending", is_selected: false },
      ],
      statusId: 3,
      total_client_users: 0,
      total_registered_users: 0,
      documentModal: false,
      isFormSubmitted: false,
      formErrors: {},
      apiError: null,
      uploaded: false,
      totalUploadedRowsCount: 0,
      uploadedApiResponse: [],
      isSelectAll: false,
      selectedValues: [],
      check_send: false,
      bulkUsersResult: [],
      bulkModal: false,
      selectReason: "User Requested",
      currentPageDataCount: 0,
      disableStatus: false,
      className: "client-user-table-block",
      checkContentPageAccess: false,
      isAccmgr: false,
      isExistingUsers: false,
      userApiStatus: null,
    };
  }
  async componentDidMount() {
    DocumentTitle(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.HEADER_TITLE);
    let params = queryString.parse(this.props.location.search);
    await ClientServices.getClientUserRoles()
      .then((res) => {
        let response = res && res.data;
        this.props.setClientRoles(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });

    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,

        sortColumn: "created_at",
        sortOrder: "desc",
        name: params.search_value ? params.search_value : "",
      },
      () => {
        this.getTableData();
      }
    );
    this.props.activeMenuTab(
      constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE
    );
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  toggleClientUserConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {data.is_active === 1 ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {data.is_active === 1 ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              Are you sure want to de-activate the user?
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              Are you sure want to activate the user?
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type: data.is_active === 1 ? "de-activate_user" : "activate_user",
        id: data.user_id,
      },
      dailogModalStyleType:
        data.is_active === 1 ? "danger-modal" : "success-modal",
    });
  };
  removeClientUserConfirmationHandler = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Remove User</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure that you want to remove the user?
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_user", id: data.id },
      dailogModalStyleType: "danger-modal",
    });
  };
  removeClientUser = (clientUserId) => {
    let arr = [];
    arr.push({ id: clientUserId });
    ClientServices.removeClientUser(arr)
      .then((res) => {
        this.getTableData();
      })
      .catch((error) => {
        this.getTableData();

        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  toggleClientUser = (clientUserId, isActive) => {
    let arr = [];
    arr.push({ id: clientUserId });
    ClientServices.toggleClientUserStatus(clientUserId, isActive)
      .then((res) => {
        Toast(
          isActive
            ? "User Activated Successfully"
            : "User De-Activated Succesfully",
          "success"
        );
        this.getTableData();
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  resendEmailVerification = (data) => {
    let arr = [];
    arr.push({ id: data.user_id });
    ClientServices.resendEmailVerification(arr)
      .then((res) => {
        Toast("Verification mail has been sent.", "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_user":
        this.removeClientUser(config.id);
        break;
      case "de-activate_user":
        this.toggleClientUser(config.id, 0);
        break;
      case "activate_user":
        this.toggleClientUser(config.id, 1);
        break;
      case "activate_user_api":
        this.userApiActivateDeactivate(config.id, true);
        break;
      case "de-activate_user_api":
        this.userApiActivateDeactivate(config.id, false);
        break;
      case "reset_password":
        this.sendResetPasswordLink(config.data);
        break;
      default:
        break;
    }
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  checkUserAccess = (data) => {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    const { short_name: loggedInRoleShortName } = tokenDetails?.role || {};
    const { short_name: targetRoleShortName } = data?.role || {};

    // If the logged-in user is trying to perform an action on their own role, return true
    if (loggedInRoleShortName === targetRoleShortName) {
      return true;
    }

    // If the logged-in user is a client_admin trying to act on a super_admin, return true
    if (
      loggedInRoleShortName === "client_admin" &&
      targetRoleShortName === "client_super_admin"
    ) {
      return true;
    }

    // If neither condition is met, return false (default case)
    return false;
  };
  getActions = (data) => {
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let isAccess =
      tokenDetails.role &&
      ["admin", "client_super_admin", "client_admin"].includes(
        tokenDetails.role.short_name
      );
    let disabled = this.checkUserAccess(data);

    return (
      <>
        {disabled ? (
          "-"
        ) : data.is_saml || data.client.is_saml ? (
          <AclAction
            type={constants.ACL.PERMISSION_KEY}
            entity={constants.ACL.CLIENT_USER_MANGEMENT.TITLE}
            action={constants.ACL.CLIENT_USER_MANGEMENT.ACTIONS.EDIT}
          >
            <button
              disabled={!isAccess}
              tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
              data-for={`tooltip-${Slugify(
                constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                  .RESEND_SAML_EMAIL_VERIFICATION_TOOLTIP
              )}-${data.id}`}
              onClick={(e) => {
                this.rowAction(
                  constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                    .RESEND_EMAIL_VERIFICATION,
                  data,
                  e
                );
              }}
              className={
                "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
              }
              data-tip={
                constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                  .RESEND_EMAIL_VERIFICATION_TOOLTIP
              }
              data-iscapture="true"
            >
              <span className="material-icons-round text-blue-clr icon-fs-20">
                send
              </span>
            </button>
          </AclAction>
        ) : (
          <>
            {data.is_active === 2 && (
              <>
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={constants.ACL.CLIENT_USER_MANGEMENT.TITLE}
                  action={constants.ACL.CLIENT_USER_MANGEMENT.ACTIONS.EDIT}
                >
                  <button
                    disabled={!isAccess}
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    data-for={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .RESEND_EMAIL_VERIFICATION_TOOLTIP
                    )}-${data.id}`}
                    onClick={(e) => {
                      this.rowAction(
                        constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                          .COPY_EMAIL_VERIFICATION_TOOLTIP,
                        data,
                        e
                      );
                    }}
                    className={
                      "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
                    }
                    data-tip={
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .COPY_EMAIL_VERIFICATION_TOOLTIP
                    }
                    data-iscapture="true"
                  >
                    <span className="material-icons-round text-blue-clr icon-fs-20">
                      add_link
                    </span>
                  </button>
                </AclAction>
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={constants.ACL.CLIENT_USER_MANGEMENT.TITLE}
                  action={constants.ACL.CLIENT_USER_MANGEMENT.ACTIONS.EDIT}
                >
                  <button
                    disabled={!isAccess}
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    data-for={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .RESEND_EMAIL_VERIFICATION_TOOLTIP
                    )}-${data.id}`}
                    onClick={(e) => {
                      this.rowAction(
                        constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                          .RESEND_EMAIL_VERIFICATION,
                        data,
                        e
                      );
                    }}
                    className={
                      "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
                    }
                    data-tip={
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .RESEND_EMAIL_VERIFICATION_TOOLTIP
                    }
                    data-iscapture="true"
                  >
                    <span className="material-icons-round text-blue-clr icon-fs-20">
                      send
                    </span>
                  </button>
                </AclAction>
              </>
            )}
            {data.is_active === 0 ||
              (data.is_active === 1 && !data.password && (
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={constants.ACL.CLIENT_USER_MANGEMENT.TITLE}
                  action={constants.ACL.CLIENT_USER_MANGEMENT.ACTIONS.LIST}
                >
                  <button
                    disabled={!isAccess || data.is_saml}
                    tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                    className={
                      "dynamicTableActionButton btn btn-md d-inline-flex align-items-right"
                    }
                    onClick={(e) =>
                      this.rowAction(
                        constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                          .RESET_PASSWORD,
                        data,
                        e
                      )
                    }
                    data-for={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .RESET_PASSWORD_TOOLTIP
                    )}-${data.id}`}
                    data-tip={
                      !data.is_set_password
                        ? "Password cannot be set as your account is managed via SAML authentication."
                        : "Send Reset Password Link"
                    }
                    data-iscapture="true"
                  >
                    <span className="material-icons-round text-blue-clr icon-fs-20">
                      {" "}
                      lock_reset
                    </span>
                  </button>
                </AclAction>
              ))}
          </>
        )}
      </>
    );
  };
  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams +=
        "&search_column=name_or_email&search_value=" + this.state.name;
    }
    if (
      this.state.statusId != 3 &&
      this.state.statusId != "all" &&
      this.state.statusId != "null"
    ) {
      queryParams += "&status=" + this.state.statusId;
    }
    this.changeRoute();
    let url = `/client-user-management/client-users`;
    let roles =
      this.props.client && this.props.client.clientRoles
        ? this.props.client.clientRoles
        : [];
    let is_role_member = roles.find(
      (r) =>
        r.id === this.props.home.loginUserSuccess.role_id &&
        r.short_name == constants.ROLES.CLIENT_USER.MEMBER
    )
      ? true
      : false;
    ClientServices.getClientUsers(
      `${url}?page=` +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let clientData = res.data.data;
        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        let total_registered_users =
          (res.data && res.data.meta && res.data.meta.total_registered_users) ||
          0;
        let total_client_users =
          (res.data && res.data.meta && res.data.meta.total_client_users) || 0;
        let usercolor = ["#606B77", "#046CD9", "#FF5A5A"];
        clientData = clientData.map((data) => {
          let disabled = data?.user_id === this.props.home.loginUserSuccess.id;
          let isUserAccess = this.checkUserAccess(data);
          const userName = `${data.first_name || ""} ${data.last_name || ""}`;
          const userColor = usercolor[data.is_active];
          return {
            id: data?.id,
            name: isUserAccess ? (
              <span
                style={{
                  color: userColor,
                  fontSize: "14px",
                  cursor: "not-allowed",
                }}
              >
                {userName}
              </span>
            ) : (
              <NavLink
                tag={RRNavLink}
                style={{ padding: 0, color: userColor, cursor: "pointer" }}
                exact
                to={formatRoute(
                  constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.ROUTE,
                  {
                    clientId: data.client.id,
                    id: data.id,
                  }
                )}
              >
                <label
                  className="mb-0"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                >
                  {userName}
                </label>
              </NavLink>
            ),
            email: (
              <span
                style={{
                  color: userColor,
                }}
              >
                {data?.email}
              </span>
            ),
            role: ["client_super_admin", "client_admin"].includes(
              data.role?.short_name
            )
              ? "Admin"
              : data.role?.name,
            logged_by: data?.user_ssos[0]
              ? data?.user_ssos[0]?.sso_type
              : "Email",
            client: data.client.name,
            created_by: data.created_by_user
              ? `${data.created_by_user.first_name || ""}  ${
                  data.created_by_user.last_name
                    ? data.created_by_user.last_name
                    : ""
                }`
              : "-",
            created_at: data.created_at
              ? DetailsDateTimeFormatter(data.created_at, false)
              : DetailsDateTimeFormatter(new Date(), false),
            last_login_at: data.last_login_datetime
              ? DetailsDateTimeFormatter(data.last_login_datetime, false)
              : "-",
            is_active_apis:
              data.open_api_status?.length > 0 ? (
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={constants.ACL.CLIENT_USER_MANGEMENT.TITLE}
                  action={constants.ACL.CLIENT_USER_MANGEMENT.ACTIONS.EDIT}
                >
                  <div className="success-toggle">
                    <label
                      className="custom-switch-checkbox success-custom-switch-checkbox"
                      htmlFor={`ActiveApi${data.id}-toggle`}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`ActiveApi${data.id}-toggle`}
                        checked={data.open_api_status.some(
                          (item) => item.is_active === true
                        )}
                        disabled={data?.client?.is_open_api_active === 0}
                        onChange={(e) => {
                          e.stopPropagation();
                          this.rowAction(
                            constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                              .API_ACTIVATION_TOGGLE,
                            data,
                            e
                          );
                        }}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </AclAction>
              ) : (
                "-"
              ),
            is_active:
              data.is_active === 0 || data.is_active === 1 ? (
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={constants.ACL.CLIENT_USER_MANGEMENT.TITLE}
                  action={constants.ACL.CLIENT_USER_MANGEMENT.ACTIONS.EDIT}
                >
                  <div className="success-toggle">
                    <label
                      className="custom-switch-checkbox success-custom-switch-checkbox"
                      htmlFor={`Status${data.id}-toggle`}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`Status${data.id}-toggle`}
                        checked={data.is_active === 1}
                        disabled={disabled || is_role_member || isUserAccess}
                        onChange={(e) => {
                          e.stopPropagation();
                          this.rowAction(
                            constants.APPLICATION_ROUTE.CLIENT_USER.LIST.STATUS,
                            data,
                            e
                          );
                        }}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </AclAction>
              ) : (
                "pending"
              ),
            actions: this.getActions(data),
          };
        });
        this.setState({
          rowData: clientData,
          count: count,
          total_registered_users: total_registered_users,
          total_client_users: total_client_users,
        });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };
  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.EDIT_USER:
        this.props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.ROUTE,
            { clientId: data.client.id, id: data.id }
          ),
        });
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.STATUS:
        this.toggleClientUserConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST
        .RESEND_EMAIL_VERIFICATION:
        this.resendEmailVerification(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST
        .RESEND_SAML_EMAIL_VERIFICATION_TOOLTIP:
        this.resendEmailVerification(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.API_ACTIVATION_TOGGLE:
        this.userApiToggle(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST
        .COPY_EMAIL_VERIFICATION_TOOLTIP:
        this.copyEmailVerificationLink(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.RESET_PASSWORD:
        this.sendResetPasswordLinkConfirmation(data);
        break;
      default:
        break;
    }
  };
  addClient = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.CLIENT_USER.CREATE.ROUTE,
        { clientId: this.state.clientId }
      ),
    });
  };
  handleClientChange = (e) => {
    let clients = [...this.props.client.clients];
    clients.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.props.setClients(clients);
    let clientId = clients.find((c) => c.is_selected === true)
      ? clients.find((c) => c.is_selected === true).id
      : "all";
    this.setState(
      {
        clientId: clientId,
        pageNo: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };
  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams +=
        "&search_column=name_or_email&search_value=" + this.state.name;
    }
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE,
        { clientId: this.state.clientId }
      ),
      search:
        "?page=" +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams,
    });
  };
  handleStatusChange = (e) => {
    this.state.status.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    let statusId = this.state.status.find((c) => c.is_selected === true).id;
    this.setState(
      {
        statusId: statusId,
        isSelectAll: false,
        selectedValues: [],
        pageNo: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };
  userApiToggle = (data) => {
    const userApiStatus =
      data.open_api_status.length > 0
        ? data.open_api_status[0].is_active
        : false;
    const user_id =
      data.open_api_status.length > 0 ? data.open_api_status[0].id : null;

    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          {userApiStatus === true ? "De-Activation" : "Activation"}
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        {userApiStatus === true ? (
          <div className="remove-body">
            <h2 className="font-weight-semibold mb-0">
              {
                constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                  .INACTIVATE_API_CONFIRM_MESSAGE
              }
            </h2>
          </div>
        ) : (
          <div className="activate-body">
            <h2 className="font-weight-semibold mb-0">
              {
                constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                  .ACTIVATE_COMPANY_CONFIRM_MESSAGE
              }
            </h2>
          </div>
        )}
      </>
    );
    this.setState({
      userApiStatus: userApiStatus,
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: {
        type:
          userApiStatus === true ? "de-activate_user_api" : "activate_user_api",
        id: user_id,
      },
      dailogModalStyleType:
        userApiStatus === true ? "danger-modal" : "success-modal",
    });
  };
  userApiActivateDeactivate = (user_id, isActive) => {
    let body = {
      is_active: isActive,
    };
    ApiCredentialServices.updateUserApiStatus(user_id, body)
      .then((res) => {
        this.getTableData();
        Toast(
          isActive
            ? constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ACTIVATE_USER
            : constants.APPLICATION_ROUTE.CLIENT_USER.LIST.IN_ACTIVATE_USER,
          "success"
        );
      })
      .catch((error) => {
        this.getTableData();
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  copyEmailVerificationLink = (user) => {
    ClientServices.copyEmailVerification({ user_id: user.id })
      .then((res) => {
        const link = res.data.verify_url;
        Copy(link);
        Toast("Copied!", "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  sendResetPasswordLinkConfirmation = (data) => {
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Reset Password Link</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            Are you sure want to send reset password link?
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "reset_password", data: data },
      dailogModalStyleType: "success-modal",
    });
  };
  sendResetPasswordLink(data) {
    let body = { email: data.email, is_client: true };
    UserServices.sendverificationmailForPasswordSetting(body)
      .then((data) => {
        Toast(data.data.message, "success");
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }
  handleClientUserTemplateDownload = () => {
    ClientServices.getClientUserStandardTemplate()
      .then((response) => {
        if (response.status === 200) {
          if (navigator.userAgent.match("CriOS")) {
            var reader = new FileReader();
            var out = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            reader.onload = function (e) {
              window.location.href = reader.result;
            };
            reader.readAsDataURL(out);
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
            const link = document.createElement("a");
            link.href = url;
            let fileName = "ClientUsersStandardtemplate";
            link.setAttribute("download", `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handleClientUserTemplateUpload = () => {
    this.setState({
      documentModal: true,
      isExistingUsers: false,
    });
  };
  isFormValid = () => {
    let formError = false;
    let docError = false;
    if (!this.state.doc || (this.state.doc && !this.state.doc.content)) {
      docError = true;
      formError = true;
    }
    this.setState({
      formErrors: {
        docError: docError,
      },
      apiError: null,
    });
    return formError;
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.isFormValid()) {
      if (
        this.state.doc &&
        this.state.doc.content &&
        this.state.doc.content.length
      ) {
        this.setState(
          {
            isFormSubmitted: true,
          },
          () => {
            //make an api call here

            ClientServices.uploadBulkClientUsers({
              content: this.state.doc.content,
              isExistingUsers: this.state.isExistingUsers,
            })
              .then((res) => {
                this.setState({
                  uploaded: true,
                  totalUploadedRowsCount: this.state.doc.content.length,
                  uploadedApiResponse: res.data.errors ? res.data.errors : [],
                });
                this.getTableData();
              })
              .catch((error) => {
                this.setState({
                  isFormSubmitted: false,
                });
                Toast(
                  error && error.data && error.data.error
                    ? error.data.error
                    : constants.ERROR.SOMETHING_WENT_WRONG,
                  "error"
                );
              });
          }
        );
      } else if (this.state.doc.content.length <= 0) {
        Toast("No data available in the file.", "warning");
      } else {
        Toast(
          "Excel sheet with more than 30 rows cannot be uploaded.",
          "warning"
        );
      }
    }
  };
  closeDocumentModal = () => {
    this.setState({
      documentModal: false,
      doc: {},
      formErrors: {},
      isFormSubmitted: false,
      uploaded: false,
      totalUploadedRowsCount: 0,
      uploadedApiResponse: [],
      bulkModal: false,
      isExistingUsers: false,
    });
  };

  onChangeHandlerFile = (name, clientUsers) => {
    let content = [];
    if (clientUsers) {
      clientUsers.forEach((clientUser) => {
        let obj = {
          first_name: clientUser["First Name"],
          last_name: clientUser["Last Name"],
          client_name: clientUser["Client"],
          email: clientUser.Email,
          mobile: clientUser["Contact No."],
          role_name: clientUser.Role,
          address: clientUser.Address,
        };
        content.push(obj);
      });
    }
    this.setState({
      doc: { name, content },
    });
  };
  render() {
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height">
          <div className=" comapnay_tbl">
            <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 pt-3">
              <h1 className="pt-0 mb-0 text-ligntblue2 font-weight-bold">
                {constants.APPLICATION_ROUTE.CLIENT_USER.LIST.HEADER_TITLE}
              </h1>
            </div>
            <div className="row align-items-center">
              <div className="col-12 col-xl-7">
                <div className="row align-items-center">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 clients_dropdown">
                    <div className="w-100-search border-search search-box-shadow-2">
                      <Search
                        handleSearch={this.handleSearch}
                        handleInput={this.handleInput}
                        value={this.state.name}
                        placeHolder={"Filter Name / Email"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 clients_dropdown">
                    <div className="custom-combobox-list">
                      <CustomDropdownList
                        data={this.state.status}
                        handleChange={this.handleStatusChange}
                        placeholder={"Status"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-auto ml-auto">
                <div className="d-flex align-items-center flex-wrap">
                  <span>
                    User Limit {this.state.total_registered_users}/
                    {this.state.total_client_users}
                  </span>
                  <AclAction
                    type="permissions"
                    entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
                    action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.DOWNLOAD}
                  >
                    <button
                      className={
                        "ml-2 btn btn-custom btn-info icon-text-btn title-btn mr-2 d-inline-flex align-items-center text-nowrap"
                      }
                      type="button"
                      onClick={this.handleClientUserTemplateDownload}
                    >
                      <span className="material-icons-round icon-fs-16 mr-1">
                        download
                      </span>
                      {" " +
                        constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                          .MULTIPLE_USERS_TEMPLATE}
                    </button>
                  </AclAction>
                  <AclAction
                    type="permissions"
                    entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
                    action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.UPLOAD}
                  >
                    <button
                      className={
                        "btn btn-custom btn-primary icon-text-btn title-btn mr-2 d-inline-flex align-items-center text-nowrap"
                      }
                      type="button"
                      onClick={this.handleClientUserTemplateUpload}
                    >
                      <span className="material-icons-round icon-fs-16 mr-1">
                        upload
                      </span>
                      {" " +
                        constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                          .MULTIPLE_USERS}
                    </button>
                  </AclAction>
                  <div className="ml-auto">
                    <aside className="d-flex justify-content-end">
                      <AclAction
                        type="permissions"
                        entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE}
                        action={
                          constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.CREATE
                        }
                      >
                        <button
                          tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                          className={
                            "btn btn-md d-inline-flex align-items-center btn-primary"
                          }
                          type="button"
                          onClick={this.addClient}
                        >
                          {/* <i className={'fa fa-plus'} /> */}
                          <span className="material-icons mr-1 icon-fs-16">
                            add
                          </span>
                          {" " +
                            constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                              .ADD_BUTTON_TITLE}
                        </button>
                      </AclAction>
                    </aside>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-100 custom-table-block custom-table-block-2 benchmark-list-table-block sort-table-block th-nowrap center-th-align">
              <DynamicTableComponent
                columnData={tableHeader}
                rowData={this.state.rowData}
                pagePrevious={this.pagePrevious}
                pageNext={this.pageNext}
                pageSize={this.state.pageSize}
                pageNo={this.state.pageNo}
                handlePage={this.handlePage}
                handleSort={this.handleSort}
                sortColumn={this.state.sortColumn}
                sortOrder={this.state.sortOrder}
                count={this.state.count}
                rowAction={this.rowAction}
                colSpan={tableHeader.length}
                showActions={showActions}
                showPagination={true}
                handlePageSize={this.handlePageSize}
                paginationClass={"center-pagination custom-pagination-new"}
              />
            </div>
            {this.state.rowData.map((data) => {
              return (
                <>
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .RESEND_EMAIL_VERIFICATION_TOOLTIP
                    )}-${data.id}`}
                    multiline={true}
                  />
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST
                        .RESET_PASSWORD_TOOLTIP
                    )}-${data.id}`}
                    multiline={true}
                  />
                </>
              );
            })}

            <Modal
              isOpen={this.state.documentModal}
              toggle={this.closeDocumentModal}
              className={`modal-md modal-w-header custom-modal primary-modal ${
                this.state.uploaded ? "uploaded_Status_table" : ""
              }`}
            >
              {!this.state.uploaded ? (
                <form
                  className={`col-lg-12 col-sm-12 form-wrapper p-0`}
                  onSubmit={this.handleSubmit}
                >
                  <ModalHeader toggle={this.closeDocumentModal}>
                    Upload Users
                  </ModalHeader>
                  <ModalBody
                    className={`${
                      this.state.isFormSubmitted &&
                      !this.state.formErrors.docError
                        ? "upload-loading-indicator"
                        : ""
                    }`}
                  >
                    <div className="container-fluid">
                      {this.state.apiError !== null && (
                        <Notification
                          color={"danger"}
                          message={this.state.apiError}
                        />
                      )}
                    </div>
                    {this.state.documentModal && (
                      <div className={"row"}>
                        <div className="col-sm-12 form-group position-relative">
                          <label htmlFor="description">
                            Document <span className={"mandatory"}>* </span>
                          </label>
                          <div>
                            <input
                              type="checkbox"
                              className="custom-checkbox-new"
                              checked={this.state.isExistingUsers}
                              onChange={(e) => {
                                this.setState({
                                  isExistingUsers: !this.state.isExistingUsers,
                                });
                              }}
                            />
                            <label className="existing-user">
                              Add existing users
                            </label>
                          </div>

                          <CustomPPTUploader
                            value={this.state.doc}
                            onChangeHandler={this.onChangeHandlerFile}
                            type={"excel"}
                            convertToJson={true}
                            isExistingUsers={this.state.isExistingUsers}
                          />
                          {this.state.formErrors.docError && (
                            <div className="error-msg clearfix">
                              <div className="empty-files">
                                {constants.FORM.MANDATORY_FIELD_FILE_UPLOAD}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-sm-12 form-group position-relative">
                          <div className="file-restriction-message text-center">
                            Note: Only Excel files are allowed
                          </div>
                        </div>
                      </div>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="modal-btn btn-outline-primary"
                      onClick={this.closeDocumentModal}
                    >
                      Cancel
                    </Button>
                    <Button className="modal-btn" color="primary" type="submit">
                      Upload
                    </Button>{" "}
                  </ModalFooter>
                </form>
              ) : (
                <>
                  <ModalHeader toggle={this.closeDocumentModal}>
                    Upload Users Status
                  </ModalHeader>
                  <ModalBody>
                    <ListClientUploadedUserStatus
                      closeDocumentModal={this.closeDocumentModal}
                      totalUploadedRowsCount={this.state.totalUploadedRowsCount}
                      data={this.state.uploadedApiResponse}
                    />
                  </ModalBody>
                </>
              )}
            </Modal>
            {this.state.bulkModal ? (
              <Modal
                isOpen={this.state.bulkModal}
                toggle={this.closebulkModal}
                className={`modal-md modal-w-header custom-modal primary-modal`}
              >
                <ModalHeader toggle={this.closeDocumentModal}>
                  Upload Users Status
                </ModalHeader>
                <ModalBody>
                  <ListUserSelectedStatus
                    closebulkModal={this.closebulkModal}
                    data={this.state.bulkUsersResult.data.errors}
                    count={this.state.selectedValues.length}
                    type={"Resent Welcome Mails"}
                  />
                </ModalBody>
              </Modal>
            ) : (
              ""
            )}

            <DailogNew
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />
          </div>
        </div>
      </aside>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    setClientRoles: (allClientRoles) => {
      dispatch(clientRoles(allClientRoles));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListClientUsers);
