import React, { Component } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import constants from "../Utils/constants";
import { formatRoute } from "react-router-named-routes";
import AclAction from "../Components/ACL/AclAction";
import { connect } from "react-redux";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import MAGIC_LINK_CONSTANTS from "./MagicLink/utils/constants";
import decodeJWT from "../lib/decodeJWT";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { addClass: false };
  }

  toggle = () => {
    this.setState({ addClass: !this.state.addClass });
  };

  render() {
    let asideClass = ["nav_sidebar"];
    if (this.state.addClass) {
      asideClass.push("closedSidebar");
    }
    let activeMenuTab =
      this.props.home && this.props.home.activeMenuTab
        ? this.props.home.activeMenuTab
        : "";

    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let isAdmin = tokenDetails?.user?.is_admin;

    return (
      <aside className="nav_sidebar">
        <div className="sidebar_section">
          <div
            className="togggle_circle"
            onClick={this.props.toggleSideBarHandler}
          >
            <div className="blue_circle">
              <i
                className={`double_arrow material-icons-outlined`}
                aria-hidden="true"
              >
                {this.props.toggleSideBar ? "chevron_left" : "chevron_right"}
              </i>
            </div>
          </div>
          <ul className="parent_ul pl-2 ml-1">
            <Nav navbar>
              <NavItem
                data-for="dashboard"
                data-tip={this.props.toggleSideBar ? "" : "Dashboard"}
                className={
                  activeMenuTab ===
                  constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE
                    ? "active"
                    : ""
                }
              >
                <NavLink
                  tag={RRNavLink}
                  exact
                  to={formatRoute(
                    constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE,
                    {}
                  )}
                  activeClassName={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <input
                    type="checkbox"
                    name=""
                    id={constants.APPLICATION_ROUTE.DASHBOARD_LIST.NAME}
                  />
                  <span className="material-icons-outlined icon-fs-20">
                    {" "}
                    dashboard
                  </span>
                  <span
                    className="label_name"
                    onClick={this.props.toggleSideBarHandler}
                    title={constants.APPLICATION_ROUTE.DASHBOARD_LIST.NAME}
                  >
                    {constants.APPLICATION_ROUTE.DASHBOARD_LIST.NAME}
                  </span>
                  <CustomReactTooltip
                    id="dashboard"
                    multiline={true}
                    place="right"
                  />
                </NavLink>
              </NavItem>
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.MY_BOARDS_RESOURCE.TITLE}
                action={constants.ACL.MY_BOARDS_RESOURCE.ACTIONS.LIST}
              >
                <NavItem
                  data-tip={this.props.toggleSideBar ? "" : "My Boards"}
                  data-for="MyBoards"
                  tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                  className={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.MY_BOARDS.LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <NavLink
                    tag={RRNavLink}
                    exact
                    to={formatRoute(
                      constants.APPLICATION_ROUTE.MY_BOARDS.LIST.ROUTE,
                      {}
                    )}
                    activeClassName={
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.MY_BOARDS.LIST.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={constants.APPLICATION_ROUTE.MY_BOARDS.LIST.NAME}
                    />
                    <span className="material-icons-outlined">push_pin</span>
                    <span
                      className="label_name"
                      onClick={this.props.toggleSideBarHandler}
                      title={constants.APPLICATION_ROUTE.MY_BOARDS.LIST.NAME}
                    >
                      {constants.APPLICATION_ROUTE.MY_BOARDS.LIST.NAME}
                    </span>
                    <CustomReactTooltip
                      id="MyBoards"
                      multiline={true}
                      place="right"
                    />
                  </NavLink>
                </NavItem>
              </AclAction>
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.MY_BOARDS_RESOURCE.TITLE}
                action={constants.ACL.MY_BOARDS_RESOURCE.ACTIONS.LIST}
              >
                <NavItem
                  data-tip={
                    this.props.toggleSideBar ? "" : "Data Visualization"
                  }
                  data-for="DataVisualization"
                  tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                  className={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW.ROUTE
                      ? "active"
                      : ""
                  }
                  style={{ position: "relative" }}
                >
                  <span className="badge badge-success beta-badge">B</span>
                  <NavLink
                    tag={RRNavLink}
                    exact
                    to={formatRoute(
                      constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW.ROUTE,
                      {}
                    )}
                    activeClassName={
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={
                        constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW
                          .ROUTE
                      }
                    />
                    <span className="material-icons-outlined">area_chart</span>
                    <span
                      className="label_name"
                      onClick={this.props.toggleSideBarHandler}
                      title={
                        constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW.NAME
                      }
                    >
                      {constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW.NAME}
                    </span>
                    <CustomReactTooltip
                      id="DataVisualization"
                      multiline={true}
                      place="right"
                    />
                  </NavLink>
                </NavItem>
              </AclAction>
              <NavItem
                data-for="companies"
                data-tip={this.props.toggleSideBar ? "" : "Company Research"}
                className={
                  activeMenuTab ===
                  constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE
                    ? "active"
                    : ""
                }
              >
                <NavLink
                  tag={RRNavLink}
                  exact
                  to={formatRoute(
                    constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE,
                    {}
                  )}
                  activeClassName={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <input
                    type="checkbox"
                    name=""
                    id={constants.APPLICATION_ROUTE.COMPANY.LIST.NAME}
                  />
                  <span className="material-icons-outlined icon-fs-20">
                    {" "}
                    meeting_room
                  </span>
                  <span
                    className="label_name"
                    onClick={this.props.toggleSideBarHandler}
                    title={constants.APPLICATION_ROUTE.COMPANY.LIST.NAME}
                  >
                    Company Research
                  </span>
                  <CustomReactTooltip
                    id="companies"
                    multiline={true}
                    place="right"
                  />
                </NavLink>
              </NavItem>
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.BENCHMARK_RESOURCE.TITLE}
                action={constants.ACL.BENCHMARK_RESOURCE.ACTIONS.LIST}
              >
                <NavItem
                  data-tip={this.props.toggleSideBar ? "" : "Benchmarks"}
                  data-for="Benchmarks"
                  tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                  className={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.BENCHMARK.LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <NavLink
                    tag={RRNavLink}
                    exact
                    to={formatRoute(
                      constants.APPLICATION_ROUTE.BENCHMARK.LIST.ROUTE,
                      {}
                    )}
                    activeClassName={
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.BENCHMARK.LIST.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={constants.APPLICATION_ROUTE.BENCHMARK.LIST.NAME}
                    />
                    <span className="material-icons-outlined">speed</span>
                    <span
                      className="label_name"
                      onClick={this.props.toggleSideBarHandler}
                      title={constants.APPLICATION_ROUTE.BENCHMARK.LIST.NAME}
                    >
                      {constants.APPLICATION_ROUTE.BENCHMARK.LIST.NAME}
                    </span>
                    <CustomReactTooltip
                      id="Benchmarks"
                      multiline={true}
                      place="right"
                    />
                  </NavLink>
                </NavItem>
              </AclAction>
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.MARKET_FORECAST_RESOURCE.TITLE}
                action={constants.ACL.MARKET_FORECAST_RESOURCE.ACTIONS.LIST}
              >
                <NavItem
                  data-tip={this.props.toggleSideBar ? "" : "Market Forecasts"}
                  data-for="MarketForecasts"
                  tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                  className={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <NavLink
                    tag={RRNavLink}
                    exact
                    to={formatRoute(
                      constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST.ROUTE,
                      {}
                    )}
                    activeClassName={
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST.NAME}
                    />
                    <span className="material-icons-outlined">trending_up</span>
                    <span
                      className="label_name"
                      onClick={this.props.toggleSideBarHandler}
                      title={
                        constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST.NAME
                      }
                    >
                      {constants.APPLICATION_ROUTE.MARKET_FORECAST.LIST.NAME}
                    </span>
                    <CustomReactTooltip
                      id="MarketForecasts"
                      multiline={true}
                      place="right"
                    />
                  </NavLink>
                </NavItem>
              </AclAction>
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.MARKET_LANDSCAPE_RESOURCE.TITLE}
                action={constants.ACL.MARKET_LANDSCAPE_RESOURCE.ACTIONS.LIST}
              >
                <NavItem
                  data-tip={this.props.toggleSideBar ? "" : "Market Landscapes"}
                  data-for="Marketlandscape"
                  tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                  className={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <NavLink
                    tag={RRNavLink}
                    exact
                    to={formatRoute(
                      constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.ROUTE,
                      {}
                    )}
                    activeClassName={
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={
                        constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.NAME
                      }
                    />
                    <span className="material-icons-outlined">analytics</span>
                    <span
                      className="label_name"
                      onClick={this.props.toggleSideBarHandler}
                      title={
                        constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.NAME
                      }
                    >
                      {constants.APPLICATION_ROUTE.MARKET_LANDSCAPE.LIST.NAME}
                    </span>
                    <CustomReactTooltip
                      id="Marketlandscape"
                      multiline={true}
                      place="right"
                    />
                  </NavLink>
                </NavItem>
              </AclAction>
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.SPECIAL_REPORTS_RESOURCE.TITLE}
                action={constants.ACL.SPECIAL_REPORTS_RESOURCE.ACTIONS.LIST}
              >
                <NavItem
                  data-tip={this.props.toggleSideBar ? "" : "Special Reports"}
                  data-for="SpecialReports"
                  tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                  className={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.SPECIAL_REPORTS.LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <NavLink
                    tag={RRNavLink}
                    exact
                    to={formatRoute(
                      constants.APPLICATION_ROUTE.SPECIAL_REPORTS.LIST.ROUTE,
                      {}
                    )}
                    activeClassName={
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.SPECIAL_REPORTS.LIST.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={constants.APPLICATION_ROUTE.SPECIAL_REPORTS.LIST.NAME}
                    />
                    <span className="material-icons-outlined">summarize</span>
                    <span
                      className="label_name"
                      onClick={this.props.toggleSideBarHandler}
                      title={
                        constants.APPLICATION_ROUTE.SPECIAL_REPORTS.LIST.NAME
                      }
                    >
                      {constants.APPLICATION_ROUTE.SPECIAL_REPORTS.LIST.NAME}
                    </span>
                    <CustomReactTooltip
                      id="SpecialReports"
                      multiline={true}
                      place="right"
                    />
                  </NavLink>
                </NavItem>
              </AclAction>
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.MARKET_LANDSCAPE_RESOURCE.TITLE}
                action={constants.ACL.MARKET_LANDSCAPE_RESOURCE.ACTIONS.LIST}
              >
                <NavItem
                  data-tip={this.props.toggleSideBar ? "" : "Customer Studies"}
                  data-for="CustomerStudies"
                  tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                  className={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <NavLink
                    tag={RRNavLink}
                    exact
                    to={formatRoute(
                      constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.ROUTE,
                      {}
                    )}
                    activeClassName={
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={
                        constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.NAME
                      }
                    />
                    <span className="material-icons-outlined">portrait</span>
                    <span
                      className="label_name"
                      onClick={this.props.toggleSideBarHandler}
                      title={
                        constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.NAME
                      }
                    >
                      {constants.APPLICATION_ROUTE.CUSTOMER_STUDIES.LIST.NAME}
                    </span>
                    <CustomReactTooltip
                      id="CustomerStudies"
                      multiline={true}
                      place="right"
                    />
                  </NavLink>
                </NavItem>
              </AclAction>
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.MARKET_LANDSCAPE_RESOURCE.TITLE}
                action={constants.ACL.MARKET_LANDSCAPE_RESOURCE.ACTIONS.LIST}
              >
                <NavItem
                  data-tip={this.props.toggleSideBar ? "" : "Ecosystems"}
                  data-for="ecosystems"
                  tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                  className={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <NavLink
                    tag={RRNavLink}
                    exact
                    to={formatRoute(
                      constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE,
                      {}
                    )}
                    activeClassName={
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.NAME}
                    />
                    <span className="material-icons-outlined icon-fs-20">
                      public
                    </span>
                    <span
                      className="label_name"
                      onClick={this.props.toggleSideBarHandler}
                      title={constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.NAME}
                    >
                      {constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.NAME}
                    </span>
                    <CustomReactTooltip
                      id="ecosystems"
                      multiline={true}
                      place="right"
                    />
                  </NavLink>
                </NavItem>
              </AclAction>
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.PROJECT_RESOURCE.TITLE}
                action={constants.ACL.PROJECT_RESOURCE.ACTIONS.LIST}
              >
                <NavItem
                  data-tip={this.props.toggleSideBar ? "" : "Projects"}
                  data-for="Projects"
                  tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                  className={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.PROJECT.LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <NavLink
                    tag={RRNavLink}
                    exact
                    to={formatRoute(
                      constants.APPLICATION_ROUTE.PROJECT.LIST.ROUTE,
                      {}
                    )}
                    activeClassName={
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.PROJECT.LIST.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={constants.APPLICATION_ROUTE.PROJECT.LIST.NAME}
                    />
                    <span className="material-icons-outlined">source</span>
                    <span
                      className="label_name"
                      onClick={this.props.toggleSideBarHandler}
                      title={constants.APPLICATION_ROUTE.PROJECT.LIST.NAME}
                    >
                      {constants.APPLICATION_ROUTE.PROJECT.LIST.NAME}
                    </span>
                    <CustomReactTooltip
                      id="Projects"
                      multiline={true}
                      place="right"
                    />
                  </NavLink>
                </NavItem>
              </AclAction>
              <NavItem
                data-tip={this.props.toggleSideBar ? "" : "Taxonomy"}
                data-for="taxonomy"
                className={
                  activeMenuTab ===
                  constants.APPLICATION_ROUTE.TAXONOMY.LIST.ROUTE
                    ? "active"
                    : ""
                }
              >
                <NavLink
                  tag={RRNavLink}
                  exact
                  to={formatRoute(
                    constants.APPLICATION_ROUTE.TAXONOMY.LIST.ROUTE,
                    {}
                  )}
                  activeClassName={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.TAXONOMY.LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <input
                    type="checkbox"
                    name=""
                    id={constants.APPLICATION_ROUTE.TAXONOMY.LIST.NAME}
                  />
                  <span className="material-icons-outlined">account_tree</span>
                  <span
                    className="label_name"
                    onClick={this.props.toggleSideBarHandler}
                    title={constants.APPLICATION_ROUTE.TAXONOMY.LIST.NAME}
                  >
                    {constants.APPLICATION_ROUTE.TAXONOMY.LIST.NAME}
                  </span>
                  <CustomReactTooltip
                    id="taxonomy"
                    multiline={true}
                    place="right"
                  />
                </NavLink>
              </NavItem>
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.MY_BOARDS_RESOURCE.TITLE}
                action={constants.ACL.MY_BOARDS_RESOURCE.ACTIONS.LIST}
              >
                <NavItem
                  data-tip={this.props.toggleSideBar ? "" : "Calendar"}
                  data-for="Webinar"
                  tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                  className={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.WEBINAR_CALENDAR.LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <NavLink
                    tag={RRNavLink}
                    exact
                    to={formatRoute(
                      constants.APPLICATION_ROUTE.WEBINAR_CALENDAR.LIST.ROUTE,
                      {}
                    )}
                    activeClassName={
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.WEBINAR_CALENDAR.LIST.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={
                        constants.APPLICATION_ROUTE.WEBINAR_CALENDAR.LIST.NAME
                      }
                    />
                    <span className="material-icons-outlined">date_range</span>
                    <span
                      className="label_name"
                      onClick={this.props.toggleSideBarHandler}
                      title={
                        constants.APPLICATION_ROUTE.WEBINAR_CALENDAR.LIST.NAME
                      }
                    >
                      {constants.APPLICATION_ROUTE.WEBINAR_CALENDAR.LIST.NAME}
                    </span>
                    <CustomReactTooltip
                      id="Webinar"
                      multiline={true}
                      place="right"
                    />
                  </NavLink>
                </NavItem>
              </AclAction>
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.CLIENT_USER_MANGEMENT.TITLE}
                action={constants.ACL.CLIENT_USER_MANGEMENT.ACTIONS.SHOW}
              >
                <NavItem
                  data-tip={this.props.toggleSideBar ? "" : "User Management"}
                  data-for="ManageUser"
                  tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                  className={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <NavLink
                    tag={RRNavLink}
                    exact
                    to={formatRoute(
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE,
                      { clientId: "all" }
                    )}
                    activeClassName={
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={constants.APPLICATION_ROUTE.CLIENT_USER.LIST.NAME}
                    />
                    <span className="material-icons-outlined">people</span>
                    <span
                      className="label_name"
                      onClick={this.props.toggleSideBarHandler}
                      title={constants.APPLICATION_ROUTE.CLIENT_USER.LIST.NAME}
                    >
                      {constants.APPLICATION_ROUTE.CLIENT_USER.LIST.NAME}
                    </span>
                    <CustomReactTooltip
                      id="ManageUser"
                      multiline={true}
                      place="right"
                    />
                  </NavLink>
                </NavItem>
              </AclAction>
              {!isAdmin && (
                <AclAction
                  type={constants.ACL.PERMISSION_KEY}
                  entity={MAGIC_LINK_CONSTANTS.MAGIC_LINK.TITLE}
                  action={MAGIC_LINK_CONSTANTS.MAGIC_LINK.ACTIONS.SHOW}
                >
                  <NavItem
                    data-tip={
                      this.props.toggleSideBar ? "" : "Magic Link Users"
                    }
                    data-for="MagicLinkUser"
                    tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                    className={
                      activeMenuTab ===
                      MAGIC_LINK_CONSTANTS.MAGIC_LINK_ROUTE.LIST.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <NavLink
                      tag={RRNavLink}
                      exact
                      to={formatRoute(
                        MAGIC_LINK_CONSTANTS.MAGIC_LINK_ROUTE.LIST.ROUTE,
                        { clientId: "all" }
                      )}
                      activeClassName={
                        activeMenuTab ===
                        MAGIC_LINK_CONSTANTS.MAGIC_LINK_ROUTE.LIST.ROUTE
                          ? "active"
                          : ""
                      }
                    >
                      <input
                        type="checkbox"
                        name=""
                        id={MAGIC_LINK_CONSTANTS.MAGIC_LINK_ROUTE.LIST.NAME}
                      />
                      <span class="material-symbols-outlined material-icons-outlined">
                        book_4_spark
                      </span>
                      <span
                        className="label_name"
                        onClick={this.props.toggleSideBarHandler}
                        title={MAGIC_LINK_CONSTANTS.MAGIC_LINK_ROUTE.LIST.NAME}
                      >
                        {MAGIC_LINK_CONSTANTS.MAGIC_LINK_ROUTE.LIST.NAME}
                      </span>
                      <CustomReactTooltip
                        id="MagicLinkUser"
                        multiline={true}
                        place="right"
                      />
                    </NavLink>
                  </NavItem>
                </AclAction>
              )}
              <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.MY_BOARDS_RESOURCE.TITLE}
                action={constants.ACL.MY_BOARDS_RESOURCE.ACTIONS.LIST}
              >
                <NavItem
                  data-tip={
                    this.props.toggleSideBar ? "" : "Resources and FAQs"
                  }
                  data-for="Resources and FAQs"
                  tbrentitytype={constants.ACL.ENTITY_TYPE.NAVIGATION}
                  className={
                    activeMenuTab ===
                    constants.APPLICATION_ROUTE.RESOURCES_AND_FAQS.LIST.ROUTE
                      ? "active"
                      : ""
                  }
                >
                  <NavLink
                    tag={RRNavLink}
                    exact
                    to={formatRoute(
                      constants.APPLICATION_ROUTE.RESOURCES_AND_FAQS.LIST.ROUTE,
                      {}
                    )}
                    activeClassName={
                      activeMenuTab ===
                      constants.APPLICATION_ROUTE.RESOURCES_AND_FAQS.LIST.ROUTE
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      name=""
                      id={
                        constants.APPLICATION_ROUTE.RESOURCES_AND_FAQS.LIST.NAME
                      }
                    />
                    <span className="material-icons-outlined">
                      contact_support
                    </span>
                    <span
                      className="label_name"
                      onClick={this.props.toggleSideBarHandler}
                      title={
                        constants.APPLICATION_ROUTE.RESOURCES_AND_FAQS.LIST.NAME
                      }
                    >
                      {
                        constants.APPLICATION_ROUTE.RESOURCES_AND_FAQS.HEADER
                          .TITLE
                      }
                    </span>
                    <CustomReactTooltip
                      id="Resources and FAQs"
                      multiline={true}
                      place="right"
                    />
                  </NavLink>
                </NavItem>
              </AclAction>
            </Nav>
          </ul>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};
export default connect(mapStateToProps, null)(Sidebar);
